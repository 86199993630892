'use client';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  useToast,
} from 'ui';
import {
  AuthenticateFormData,
  AuthenticateFormProps,
} from './AuthenticateForm';
import { PiramidLogo } from './PiramidLogo';
import { AuthLink } from './AuthLink';

export type AuthenticateProps = {
  title: string;
  description: string;
  inverseAction:
    | {
        type: 'callback';
        callback: () => void;
        destination: string;
        question: string;
      }
    | {
        type: 'path';
        path: string;
        destination: string;
        question: string;
      };
  back: {
    label: string;
  };
  submit: {
    label: string;
    handle: (data: AuthenticateFormData) => Promise<any>;
    forceVerification?: boolean;
  };
  callbacks: {
    onDone: () => void;
  };
} & Pick<AuthenticateFormProps, 'context'>;

export const AuthenticateMoreOptionsTrigger = () => {
  return (
    <AccordionTrigger
      chevronIconClassName='text-piramid-black'
      className='no-underline flex flex-row items-center justify-center w-78 py-2 focus:no-underline'
    >
      <span className='me-1 text-piramid-black text-base_brand font-semibold pointer-events-auto'>
        Más opciones
      </span>
    </AccordionTrigger>
  );
};

export const AuthenticationIDPContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='flex flex-col space-y-2 w-78'>{children}</div>;
};

export const AuthenticateTOS = () => {
  return (
    <p className='text-[10px] w-78 mt-2 text-stone-300'>
      Al hacer clic en Continuar, reconoce que ha leído y acepta los Términos de
      servicio y la Política de privacidad de Piramid.
    </p>
  );
};

export const AuthenticateLogo = () => {
  return (
    <div className='w-10 h-10'>
      <PiramidLogo variant='orange' />
    </div>
  );
};

export const AuthenticateContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='w-[321px]'>{children}</div>;
};

export const AuthenticateTitle = ({ children }: { children: string }) => {
  return (
    <h1 className='font-semibold text-[2rem] my-4 text-piramid-black tracking-tighter'>
      {children}
    </h1>
  );
};

export const AuthenticateDescription = ({ children }: { children: string }) => {
  return (
    <p className='text-stone-300 font-semibold mb-4 text-[22px] tracking-[-0.03em] leading-[1.875rem] whitespace-pre-line'>
      {children}
    </p>
  );
};

export const AuthenticationMoreOptions = ({
  handleContinueWithEmail,
  inverseAction,
}: {
  handleContinueWithEmail: () => void;
  inverseAction?: AuthenticateProps['inverseAction'];
}) => {
  return (
    <Accordion type='single' collapsible>
      <AccordionItem key={'more-options'} value={'more-options'}>
        <AuthenticateMoreOptionsTrigger />
        <AccordionContent className='flex-col items-center justify-center w-78'>
          <Button
            className='bg-beige text-piramid-black rounded-xl hover:bg-beige w-full h-11.1 px-4 py-2 mt-2'
            onClick={handleContinueWithEmail}
          >
            <span className='ml-2 font-semibold text-[.875rem]'>
              Continuar con email
            </span>
          </Button>

          {!!inverseAction && (
            <div className='mt-6 text-center'>
              {inverseAction.type === 'callback' ? (
                <AuthLink
                  {...inverseAction}
                  asButton
                  onClick={inverseAction.callback}
                />
              ) : (
                <AuthLink asButton={false} {...inverseAction} />
              )}
            </div>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
