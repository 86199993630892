import { IconPaperclip } from '@tabler/icons-react';
import { cn } from 'ui';
import { FileCardIcon } from './FileCard';

export const FileList = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div role='file-list-group' className={cn(className)}>
      {children}
    </div>
  );
};

export const FileListItem = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      role='file-list-group'
      className={cn(
        'border-[1px] h-10 justify-between w-full rounded-sm flex flex-row items-center text-card-foreground bg-card',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const FileListLabel = ({
  children,
  className,
  mimetype,
  withMimetypeIcon = true,
  withDefaultIcon = false,
  textClassName,
}: {
  children: React.ReactNode;
  className?: string;
  mimetype?: string | undefined | null;
  withMimetypeIcon?: boolean;
  withDefaultIcon?: boolean;
  textClassName?: string;
}) => {
  return (
    <div className={cn('flex flex-row items-center mr-2 max-w-48', className)}>
      {withMimetypeIcon && mimetype && <FileCardIcon mimetype={mimetype} />}
      {withMimetypeIcon && !mimetype && (
        <IconPaperclip className='mr-2 flex-shrink-0 w-3 h-3' />
      )}
      {withDefaultIcon && (
        <IconPaperclip className='mr-2 flex-shrink-0 w-3 h-3' />
      )}
      <p className={cn('text-[.625rem] truncate block', textClassName)}>
        {children}
      </p>
    </div>
  );
};
