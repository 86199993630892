'use client';

import { useQuery } from '@tanstack/react-query';
import { EventComplete, ListEventsResponseBody } from 'bff';
import { Activity, useEvent } from 'shared-components';
import { WaitForQuery } from './WaitForQuery';
import es from 'date-fns/locale/es';
import { customFormatDistance } from 'utils';
import { UserType } from 'database';

export const buildListEventsQueryKey = (resourceId: string) =>
  `list-events-${resourceId}`;

export const ActivityTab = ({
  initialData,
  mountEvent,
  fetchEvents,
  id,
  httpClient,
  token,
  portal,
  userType,
  path,
}: {
  initialData: ListEventsResponseBody;
  mountEvent: NonNullable<Parameters<typeof useEvent>[1]>[number];
  fetchEvents: () => Promise<{
    events: EventComplete[];
  }>;
  id: string;
  httpClient: unknown;
  token?: string;
  portal?: boolean;
  userType?: UserType;
  path?: string;
}) => {
  const query = useQuery([buildListEventsQueryKey(id)], () => fetchEvents(), {
    initialData,
  });

  if (portal) {
    useEvent(httpClient as any, [mountEvent], '/portal', token);
  } else {
    useEvent(httpClient as any, [mountEvent]);
  }

  return (
    <WaitForQuery query={query}>
      {({ events }) => (
        <Activity userType={userType}>
          {events
            .filter(
              (event) =>
                !(
                  event.type === 'claim_comment_created' &&
                  userType === 'inhouse_lawyer'
                ),
            )
            .map((event, index) => (
              <Activity.Item
                index={index}
                event={event}
                eventsCount={events.length}
                key={event.id}
                userType={userType}
              >
                <Activity.Content>
                  <Activity.Header eventType={event.type}>
                    <Activity.TriggeredInfo event={event} path={path} />
                    <Activity.TimeAgo className='mt-0'>
                      <span className='text-end'>
                        {customFormatDistance(
                          new Date(),
                          new Date(event.created_at),
                          {
                            locale: es,
                            justDate: true,
                          },
                        )}
                      </span>
                      <span className='text-stone-400 text-end'>
                        {customFormatDistance(
                          new Date(),
                          new Date(event.created_at),
                          {
                            locale: es,
                            justSuffix: true,
                          },
                        )}
                      </span>
                    </Activity.TimeAgo>
                  </Activity.Header>
                </Activity.Content>
              </Activity.Item>
            ))}
        </Activity>
      )}
    </WaitForQuery>
  );
};
