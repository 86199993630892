import { addYears, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import {
  FormItem,
  FormLabel,
  Popover,
  PopoverTrigger,
  FormControl,
  Button,
  cn,
  PopoverContent,
  FormMessage,
  Calendar,
  ButtonProps,
} from 'ui';

export const DateInputField = ({
  value,
  onChange,
  label,
  disabled,
  size,
  labelClassName,
  readonly = false,
  className,
}: {
  value?: Date;
  onChange: (...event: any[]) => void;
  label: string;
  disabled?: (date: Date) => boolean;
  size?: ButtonProps['size'];
  labelClassName?: string;
  readonly?: boolean;
  className?: string;
}) => {
  return (
    <FormItem className={className}>
      <FormLabel className={labelClassName}>{label}</FormLabel>
      <Popover>
        <PopoverTrigger disabled={readonly} asChild>
          <FormControl>
            <Button
              disabled={readonly}
              variant={'outline'}
              className={cn(
                'w-full pl-3 text-left font-normal',
                !value && 'text-muted-foreground',
              )}
              size={size}
            >
              {value ? (
                format(new Date(value), 'PPP', {
                  locale: es,
                })
              ) : (
                <span className={labelClassName}>Selecciona una fecha</span>
              )}
              <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          <Calendar
            mode='single'
            locale={es}
            toDate={addYears(new Date(), 3)}
            defaultMonth={value ? new Date(value) : undefined}
            selected={value}
            onSelect={onChange}
            disabled={
              disabled
                ? disabled
                : (date) => date > new Date() || date < new Date('1900-01-01')
            }
            initialFocus
          />
        </PopoverContent>
      </Popover>
      <FormMessage />
    </FormItem>
  );
};
