'use client';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, memo } from 'react';
import { ListFilesResponseBody } from 'bff';
import {
  FileCard,
  FileCardContent,
  FileCardPreview,
  SINGLE_FILE_QUERY_KEY,
} from 'shared-components';
import { cn } from 'ui';
import { IconTag } from '@tabler/icons-react';
import { FileTypes } from 'database';
import { useFileViewer } from './FileViewerContext';

type File = ListFilesResponseBody['files'][number] & {
  _version?: number;
  type: FileTypes;
};

interface MemoizedFileCardProps {
  file: File;
  allFiles?: File[];
  onOpenFile?: ((file: File) => void) | undefined;
}

const MemoizedFileCard: React.FC<MemoizedFileCardProps> = memo(
  ({ file, allFiles = [], onOpenFile }) => {
    const { openFileViewer, fetchFile } = useFileViewer();

    const fileQuery = useQuery({
      queryKey: [SINGLE_FILE_QUERY_KEY, file.id] as const,
      queryFn: async () => {
        const fileUpdated = await fetchFile?.(file.id);
        return fileUpdated;
      },
      initialData: file as any,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    });

    const handleOpenFile = useCallback(() => {
      onOpenFile?.(file);
      openFileViewer(fileQuery.data, allFiles);
    }, [file, allFiles, onOpenFile, openFileViewer, fileQuery.data]);

    return (
      <FileCard
        className='relative h-[12.8rem] flex items-center justify-center group shadow-none rounded-sm'
        onClick={handleOpenFile}
      >
        <FileCardContent className='p-0 flex flex-col items-center shadow-none'>
          <FileCardPreview
            type={fileQuery.data?.type}
            previewURL={
              fileQuery.data?.type === 'image'
                ? fileQuery.data?.url
                : fileQuery.data?.previewURL
            }
            mode='thumbnail'
            className={cn('h-44 px-6', {
              'w-80': fileQuery.data?.type !== FileTypes.image,
            })}
          />
          <div className='flex flex-row items-center justify-between w-full group-hover:visible invisible absolute bottom-0 left-0 transition-transform bg-gradient-to-t from-stone-50 via-stone-50 to-transparent h-12 px-4'>
            {fileQuery.data?.tags && fileQuery.data?.tags.length > 0 && (
              <div className='flex flex-row items-center w-11/12 gap-2'>
                <IconTag className='w-4 h-4 text-primary' />
                <p className='text-xs font-medium text-stone-700 truncate z-10'>
                  {fileQuery.data?.tags.map((tag: any) => tag.name).join(', ')}
                </p>
              </div>
            )}
          </div>
        </FileCardContent>
      </FileCard>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.file._version !== nextProps.file._version) {
      return false;
    }

    if (prevProps.file.id !== nextProps.file.id) {
      return false;
    }

    if (prevProps.allFiles?.length !== nextProps.allFiles?.length) {
      return false;
    }

    const prevAllFilesVersions = prevProps.allFiles
      ?.map((f) => f._version)
      .join(',');
    const nextAllFilesVersions = nextProps.allFiles
      ?.map((f) => f._version)
      .join(',');
    if (prevAllFilesVersions !== nextAllFilesVersions) {
      return false;
    }

    return true;
  },
);

export default MemoizedFileCard;
