'use client';

import React, { useState, useCallback, useRef, version } from 'react';
import { ListFilesResponseBody } from 'bff';
import {
  IconChevronLeft,
  IconChevronRight,
  IconDownload,
} from '@tabler/icons-react';
import {
  AccordionTrigger,
  AccordionContent,
  AccordionItem,
  Accordion,
  Button,
  cn,
  Card,
  CardContent,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
  TooltipProvider,
} from 'ui';
import { format } from 'date-fns';
import { getHumanFileSize } from 'utils';
import { FileCardIcon } from './FileCard';

type File = ListFilesResponseBody['files'][number] & {
  _version?: number;
};

interface FileCarouselProps {
  files: File[];
  currentFile: File;
  currentIndex: number;
  onPrevious: () => void;
  onNext: () => void;
  children: React.ReactNode;
}

export const FileCarousel = ({
  files,
  currentFile,
  currentIndex,
  onPrevious,
  onNext,
  children,
}: FileCarouselProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const prevIndexRef = useRef<number>(currentIndex);

  const handleThumbnailRef = useCallback((node: HTMLImageElement | null) => {
    if (node && node.parentElement) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, []);

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const handleWheel = useCallback((e: React.WheelEvent<HTMLDivElement>) => {
    if (!scrollContainerRef.current) return;

    e.preventDefault();
    scrollContainerRef.current.scrollLeft += e.deltaY;
  }, []);

  const hasPrevious = currentIndex > 0;
  const hasNext = currentIndex < files.length - 1;

  const handlePrevious = useCallback(() => {
    setIsOpen(false);
    onPrevious();
  }, [onPrevious]);

  const handleNext = useCallback(() => {
    setIsOpen(false);
    onNext();
  }, [onNext]);

  return (
    <div className='relative w-full h-full flex bg-stone-50 rounded-[.625rem]'>
      {isOpen && <div className='absolute inset-0 backdrop-blur-sm z-10' />}
      <div
        className={cn(
          'absolute left-0 top-[46%] h-12 w-12 flex items-center justify-center z-[100]',
          {
            'opacity-0': files.length <= 1,
          },
        )}
      >
        <Button
          variant='ghost'
          size='icon'
          className={cn(
            'h-8 w-8 bg-stone-700 rounded-full hover:bg-stone-700',
            !hasPrevious && 'opacity-50 cursor-not-allowed',
          )}
          onClick={handlePrevious}
          disabled={!hasPrevious}
          aria-label='Archivo anterior'
          tabIndex={-1}
        >
          <IconChevronLeft className='h-6 w-6 text-primary' />
        </Button>
      </div>

      <div className='flex-1 relative'>
        <div className='absolute bottom-[.875rem] left-4 z-[200]'>
          <Accordion className='space-y-5' type={'single'} collapsible>
            <AccordionItem
              key={currentFile.id.toString()}
              className='border-b-0'
              value={currentFile.id.toString()}
            >
              <Card
                className='shadow-none w-[16.875rem] bg-piramid-black opacity-85 text-white border-none'
                tabIndex={-1}
              >
                <CardContent className='p-0'>
                  <AccordionTrigger
                    withChevron={false}
                    className='hover:no-underline p-4'
                    onClick={() => setIsOpen(!isOpen)}
                    tabIndex={-1}
                  >
                    <div className='w-full grid grid-cols-4'>
                      <div className='flex flex-row items-center justify-start space-x-1 col-span-3'>
                        <FileCardIcon
                          mimetype={currentFile.mimetype ?? ''}
                          className='w-[1.0625rem] h-[1.0625rem] text-primary'
                        />
                        <TooltipProvider delayDuration={0}>
                          <Tooltip>
                            <TooltipTrigger tabIndex={-1}>
                              <p className='text-xs font-medium text-white p-0 truncate max-w-24'>
                                {currentFile.original_name}
                              </p>
                            </TooltipTrigger>
                            <TooltipContent
                              className='bg-piramid-black text-white border-none'
                              tabIndex={-1}
                            >
                              <p className='text-xs'>
                                {currentFile.original_name}
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                        <a
                          href={currentFile.url}
                          target='_blank'
                          className='flex items-center justify-center text-white bg-transparent hover:bg-transparent w-5 h-5'
                        >
                          <IconDownload className='w-4 h-4 text-white' />
                        </a>
                      </div>
                      <p className='text-2xs text-white col-span-1'>
                        {isOpen ? 'Ver menos' : 'Ver más'}
                      </p>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className='px-4'>
                    {currentFile.InferredFileData?.summary && (
                      <div className='flex flex-col mb-6'>
                        <h2 className='px-0 font-semibold text-base_brand text-white mb-2'>
                          Resumen
                        </h2>
                        <p className='text-xs text-white h-28 overflow-y-auto p-0 text-justify'>
                          {currentFile.InferredFileData.summary}
                        </p>
                      </div>
                    )}
                    <div className={'flex flex-col'}>
                      <h2 className='px-0 font-semibold text-base_brand text-white mb-2'>
                        Información de la imagen
                      </h2>
                      <ul className='flex flex-col text-base_brand overflow-y-auto max-h-[20rem] space-y-1'>
                        <li className='grid grid-cols-2 w-full'>
                          <span className='col-span-1 text-white font-medium text-xs'>
                            Tipo
                          </span>
                          <span className='col-span-1 font-medium text-white text-xs'>
                            {`.${currentFile.extension}`}
                          </span>
                        </li>
                        <li className='grid grid-cols-2 w-full'>
                          <span className='col-span-1 text-white font-medium text-xs'>
                            Peso
                          </span>
                          <span className='col-span-1 font-medium text-white text-xs'>
                            {getHumanFileSize(Number(currentFile.size))}
                          </span>
                        </li>
                        {currentFile.uploaded_by && (
                          <li className='grid grid-cols-2 w-full'>
                            <span className='col-span-1 text-white font-medium text-xs'>
                              Subido por
                            </span>
                            <span className='col-span-1 font-medium text-white text-xs'>
                              {currentFile.uploaded_by.first_name}{' '}
                              {currentFile.uploaded_by.last_name}
                            </span>
                          </li>
                        )}
                        {currentFile.created_at && (
                          <li className='grid grid-cols-2 w-full'>
                            <span className='col-span-1 text-white font-medium text-xs'>
                              Fecha de subida
                            </span>
                            <span className='col-span-1 font-medium text-white text-xs'>
                              {format(
                                new Date(currentFile.created_at),
                                'dd/MM/yyyy, HH:mm',
                              )}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </AccordionContent>
                </CardContent>
              </Card>
            </AccordionItem>
          </Accordion>
        </div>
        <div className='w-full h-full'>{children}</div>
      </div>

      <div
        className={cn(
          'absolute right-0 top-[46%] h-12 w-12 flex items-center justify-center z-[100]',
          {
            'opacity-0': files.length <= 1,
          },
        )}
      >
        <Button
          variant='ghost'
          size='icon'
          className={cn(
            'h-8 w-8 bg-stone-700 rounded-full hover:bg-stone-700',
            !hasNext && 'opacity-50 cursor-not-allowed',
          )}
          onClick={handleNext}
          disabled={!hasNext}
          aria-label='Archivo siguiente'
          tabIndex={-1}
        >
          <IconChevronRight className='h-6 w-6 text-primary' />
        </Button>
      </div>

      {files.length > 1 && (
        <div
          ref={scrollContainerRef}
          className='absolute -bottom-16 left-0 right-0 flex items-center justify-start rounded-[.625rem] overflow-x-auto'
          style={{
            scrollbarWidth: 'none',
          }}
          onWheel={handleWheel}
        >
          {files.map((file, index) => (
            <img
              key={file.id}
              ref={index === currentIndex ? handleThumbnailRef : null}
              src={file.type === 'image' ? file.url : file.previewURL}
              alt='File carousel indicator'
              className={cn(
                'h-11 transition-colors',
                index === currentIndex ? 'brightness-100' : 'brightness-[40%]',
              )}
              onClick={() => {}}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/images/preview-placeholder.jpg';
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
