'use client';

import * as React from 'react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { cn } from '../lib/utils';

/**
 * Augments native scroll functionality for custom, cross-browser styling.
 */
const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    orientation: 'vertical' | 'horizontal';
    withContentPadding?: boolean;
    viewportClassName?: string;
    refPlace?: 'root' | 'viewport';
    scrollToBottom?: boolean;
  }
>(
  (
    {
      className,
      children,
      withContentPadding,
      orientation = 'vertical',
      viewportClassName,
      refPlace = 'root',
      scrollToBottom,
      ...props
    },
    ref,
  ) => {
    const viewportRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      if (scrollToBottom && viewportRef.current) {
        viewportRef.current.scrollTop = viewportRef.current.scrollHeight;
      }
    }, [scrollToBottom, children]);

    return (
      <ScrollAreaPrimitive.Root
        ref={refPlace === 'root' ? ref : undefined}
        className={cn('relative overflow-hidden h-full', className)}
        {...props}
      >
        <ScrollAreaPrimitive.Viewport
          ref={(node) => {
            if (refPlace === 'viewport' && ref) {
              // @ts-ignore - ref is a ForwardedRef
              ref.current = node;
            }
            viewportRef.current = node;
          }}
          className={cn(
            'h-full w-full rounded-[inherit]',
            {
              'px-8 py-8': withContentPadding,
            },
            viewportClassName,
          )}
        >
          {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar className='h-full' orientation={orientation} />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    );
  },
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      'flex touch-none select-none transition-colors',
      orientation === 'vertical' &&
        'h-full w-2.5 border-l border-l-transparent p-[1px]',
      orientation === 'horizontal' &&
        'h-2.5 border-t border-t-transparent p-[1px]',
      className,
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className='relative flex-1 rounded-full bg-border' />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
