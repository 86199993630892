import Link from 'next/link';
import React from 'react';

export interface AuthLinkProps {
  path: string;
  asButton?: boolean;
  onClick?: () => void;
  question: string;
  destination: string;
}

export const AuthLink = ({
  onClick,
  asButton,
  destination,
  question,
  path,
}: AuthLinkProps) => {
  return (
    <div className='w-full items-start justify-start'>
      <p className='text-piramid-text text-xs font-medium'>
        {question}{' '}
        {asButton ? (
          <button className='text-primary font-semibold' onClick={onClick}>
            {destination}
          </button>
        ) : (
          <Link className='text-primary font-semibold' href={path}>
            {destination}
          </Link>
        )}
      </p>
    </div>
  );
};
