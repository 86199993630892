'use client';

import React, { useState, useRef, useEffect } from 'react';
import { IconCheck, IconPencil, IconX } from '@tabler/icons-react';
import { Button, cn, Input, Textarea } from 'ui';
import { UpdateInferredKeywordRequestBody } from 'bff';

interface InferredKeyword {
  id: string;
  key: string;
  value: string | null;
  type?: string;
  created_at?: Date;
  inferred_file_data_id?: string;
  non_registered_value_type?: unknown;
}

interface InferredFileDataItem {
  id: string;
  keywords: InferredKeyword[];
  response?: unknown;
  created_at?: Date;
  file_id?: string;
  manual_updates?: unknown[];
}

interface InferredFileDataEditorProps {
  fileId: string;
  inferredFileData: InferredFileDataItem;
  onUpdateKeyword?: (
    fileId: string,
    inferredFileDataId: string,
    keywordId: string,
    field: 'key' | 'value',
    newValue: string,
  ) => Promise<UpdateInferredKeywordRequestBody> | undefined;
  userType?: string;
}

export const InferredFileDataEditor = ({
  fileId,
  inferredFileData,
  onUpdateKeyword,
  userType,
}: InferredFileDataEditorProps) => {
  const [editingField, setEditingField] = useState<{
    inferredFileDataId: string;
    keywordId: string;
    field: 'key' | 'value';
  } | null>(null);

  const [localKeywords, setLocalKeywords] = useState<InferredKeyword[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Initialize local keywords with the props data
  useEffect(() => {
    if (inferredFileData?.keywords) {
      setLocalKeywords([...inferredFileData.keywords]);
    }
  }, [inferredFileData]);

  // Focus input when editing starts
  useEffect(() => {
    if (editingField && inputRef.current) {
      inputRef.current.focus();
    }
    if (editingField && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [editingField]);

  // Add click outside listener
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editingField &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        inputRef.current !== event.target &&
        textareaRef.current !== event.target
      ) {
        cancelEditing();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingField]);

  const handleFieldClick = (
    inferredFileDataId: string,
    keywordId: string,
    field: 'key' | 'value',
    currentValue: string | null,
  ) => {
    if (isUpdating) return;

    setEditingField({
      inferredFileDataId,
      keywordId,
      field,
    });
    setInputValue(currentValue || '');
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (e.key === 'Enter' && editingField) {
      saveChanges();
    } else if (e.key === 'Escape') {
      cancelEditing();
    }
  };

  const saveChanges = async () => {
    if (editingField) {
      const { inferredFileDataId, keywordId, field } = editingField;

      setIsUpdating(true);
      const updatedKeywords = localKeywords.map((keyword) => {
        if (keyword.id === keywordId) {
          return {
            ...keyword,
            [field]: inputValue,
          };
        }
        return keyword;
      });

      setLocalKeywords(updatedKeywords);
      setEditingField(null);

      if (onUpdateKeyword) {
        // @ts-ignore
        onUpdateKeyword(
          fileId,
          inferredFileDataId,
          keywordId,
          field,
          inputValue,
        )
          .then(() => {
            setIsUpdating(false);
          })
          .catch((error) => {
            console.error('Error updating keyword:', error);
            setLocalKeywords([...inferredFileData.keywords]);
            setIsUpdating(false);
          });
      } else {
        setIsUpdating(false);
      }
    }
  };

  const cancelEditing = () => {
    setEditingField(null);
  };

  if (!inferredFileData) {
    return null;
  }

  return (
    <div className='flex flex-col space-y-4 pb-2' ref={containerRef}>
      <div
        key={inferredFileData.id}
        className='flex flex-col text-xs space-y-2'
      >
        {localKeywords.map((keyword) => (
          <div key={keyword.id} className='grid grid-cols-2 w-full'>
            <div className='col-span-2'>
              {editingField &&
              editingField.inferredFileDataId === inferredFileData.id &&
              editingField.keywordId === keyword.id &&
              editingField.field === 'key' &&
              onUpdateKeyword ? (
                <div className='grid grid-cols-3 w-full h-5'>
                  <Input
                    ref={inputRef}
                    type='text'
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    variant={'outlineNoRings'}
                    className='text-xs h-5 leading-5 text-stone-400 border-none rounded-sm p-0 col-span-2 box-border m-0'
                  />
                  <div className='flex flex-row items-center justify-end mr-1 w-full col-span-1 h-5'>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        saveChanges();
                      }}
                      type='button'
                      size='xs'
                      variant={'ghost'}
                      loadingLayout='over'
                      className='bg-transparent h-5 p-0'
                      classNames={{
                        spinner: '!size-[0.875rem]',
                      }}
                      disabled={isUpdating}
                    >
                      <IconCheck className='size-[0.875rem] text-stone-400' />
                    </Button>
                    <Button
                      onClick={cancelEditing}
                      type='button'
                      size='xs'
                      variant={'ghost'}
                      disabled={isUpdating}
                      className='bg-transparent h-5 p-0'
                    >
                      <IconX className='size-[0.875rem] text-stone-400' />
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className={cn('flex items-center group h-5 box-border m-0', {
                    'cursor-pointer': !isUpdating,
                    'cursor-default':
                      !onUpdateKeyword ||
                      userType === 'third' ||
                      userType === 'inhouse_lawyer',
                  })}
                  onClick={() => {
                    if (
                      !onUpdateKeyword ||
                      userType === 'third' ||
                      userType === 'inhouse_lawyer'
                    ) {
                      return;
                    }
                    !isUpdating &&
                      handleFieldClick(
                        inferredFileData.id,
                        keyword.id,
                        'key',
                        keyword.key,
                      );
                  }}
                >
                  <span className='text-stone-400 pe-1 align-middle leading-5'>
                    {keyword.key}
                  </span>
                </div>
              )}
            </div>
            <div className='col-span-2'>
              {editingField &&
              editingField.inferredFileDataId === inferredFileData.id &&
              editingField.keywordId === keyword.id &&
              editingField.field === 'value' &&
              onUpdateKeyword ? (
                <div className='grid grid-cols-5 w-full'>
                  {inputValue.length <= 50 ? (
                    <Input
                      ref={inputRef}
                      type='text'
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      variant={'outlineNoRings'}
                      className='font-bold text-xs h-5 leading-5 text-piramid-text border-none rounded-none p-0 col-span-4 box-border m-0'
                    />
                  ) : (
                    <Textarea
                      ref={textareaRef}
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      className='font-bold text-xs min-h-5 text-piramid-text border-none rounded-none p-0 col-span-4 box-border m-0 leading-5'
                      resize={false}
                    />
                  )}
                  <div className='flex flex-row items-center justify-end mr-1 w-full h-5 col-span-1'>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        saveChanges();
                      }}
                      type='button'
                      size='xs'
                      variant={'ghost'}
                      loadingLayout='over'
                      className='bg-transparent h-5 p-0'
                      classNames={{
                        spinner: '!size-[0.875rem]',
                      }}
                      disabled={isUpdating}
                    >
                      <IconCheck className='size-[0.875rem] text-stone-400' />
                    </Button>
                    <Button
                      onClick={cancelEditing}
                      type='button'
                      size='xs'
                      variant={'ghost'}
                      disabled={isUpdating}
                      className='bg-transparent h-5 p-0'
                    >
                      <IconX className='size-[0.875rem] text-stone-400' />
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className={cn(
                    'flex items-start group min-h-5 box-border m-0',
                    {
                      'cursor-pointer': !isUpdating,
                      'cursor-default':
                        !onUpdateKeyword ||
                        userType === 'third' ||
                        userType === 'inhouse_lawyer',
                    },
                  )}
                  onClick={() => {
                    if (
                      !onUpdateKeyword ||
                      userType === 'third' ||
                      userType === 'inhouse_lawyer'
                    ) {
                      return;
                    }
                    !isUpdating &&
                      handleFieldClick(
                        inferredFileData.id,
                        keyword.id,
                        'value',
                        keyword.value,
                      );
                  }}
                >
                  <span className='font-bold text-stone-700 leading-5 pt-0'>
                    {keyword.value || ''}
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
