import { WretchError } from 'wretch/resolver';
import { useToast, ToastAction } from 'ui';
import { useTranslations } from 'i11n';
import { useCallback } from 'react';
import { LoginFormData } from '../components/AuthenticateForm';
import { UserNotVerifiedErrorMetadata } from 'bff';

export interface ApiErrorCallbacks<THydrated extends boolean = false> {
  user_not_verified: (
    data: THydrated extends false
      ? { errorMetadata: UserNotVerifiedErrorMetadata }
      : {
          errorMetadata: UserNotVerifiedErrorMetadata;
          credentials: LoginFormData;
        },
  ) => Promise<any>;
}

export const useApiError = (callbacks?: ApiErrorCallbacks) => {
  const { toast } = useToast();

  const t = useTranslations();

  const handleError = useCallback(
    async (error: WretchError) => {
      const code = error.json.code || 'unexpected_error';

      const callback = callbacks?.[code];

      if (callback) {
        await callback({ errorMetadata: error.json.metadata });

        return {
          callbackInvoked: true,
        };
      }

      toast({
        title: t(`errors.${code}.title`, error.json.metadata || {}),
        description: t(`errors.${code}.description`, error.json.metadata || {}),
      });

      return {
        callbackInvoked: false,
      };
    },
    [callbacks],
  );

  return {
    handleError,
  };
};
