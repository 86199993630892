import React from 'react';
import { cn } from '../lib/utils';
import { cva } from 'class-variance-authority';

const iconVariants = cva('h-[5.563rem]', {
  variants: {
    variant: {
      default: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export const NextEmptyStateIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    uploaded?: boolean;
  },
) => {
  if (props.uploaded)
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 132 98'
        className={cn(iconVariants({ className: props.className }))}
      >
        <g
          stroke='#A8A29E'
          strokeLinecap='round'
          strokeLinejoin='round'
          clipPath='url(#clip0_4214_17437)'
        >
          <path
            fill='#fff'
            d='m75.213 65.827-27.4 13.76a6.2 6.2 0 0 1-4.662.379 5.92 5.92 0 0 1-3.506-2.979L20.831 39.524a5.92 5.92 0 0 1-.295-4.59 6.2 6.2 0 0 1 3.087-3.514l19.18-9.632 20.42 6.5 14.782 29.435a5.92 5.92 0 0 1 .295 4.59 6.2 6.2 0 0 1-3.087 3.514'
          ></path>
          <path d='m42.803 21.788 5.376 10.704a2.96 2.96 0 0 0 1.752 1.49 3.1 3.1 0 0 0 2.331-.19l10.96-5.504'></path>
        </g>
        <g
          stroke='#A8A29E'
          strokeLinecap='round'
          strokeLinejoin='round'
          clipPath='url(#clip1_4214_17437)'
        >
          <path
            fill='#fff'
            d='m88.657 79.028-29.104-9.645a6.2 6.2 0 0 1-3.564-3.028 5.92 5.92 0 0 1-.373-4.585l13.187-39.795a5.92 5.92 0 0 1 3.037-3.454 6.2 6.2 0 0 1 4.668-.302l20.373 6.752 9.842 19.034-10.361 31.267a5.92 5.92 0 0 1-3.037 3.455 6.2 6.2 0 0 1-4.668.301'
          ></path>
          <path d='m96.88 24.97-3.768 11.37a2.96 2.96 0 0 0 .187 2.292 3.1 3.1 0 0 0 1.782 1.515l11.641 3.858'></path>
        </g>
        <g
          stroke='#A8A29E'
          strokeLinecap='round'
          strokeLinejoin='round'
          clipPath='url(#clip2_4214_17437)'
        >
          <path
            fill='#fff'
            d='M82.833 81.642H45.747a7.42 7.42 0 0 1-7.418-7.417V22.304a7.417 7.417 0 0 1 7.418-7.417h25.96L90.25 33.43v40.795a7.417 7.417 0 0 1-7.417 7.417'
          ></path>
          <path d='M71.707 14.887V29.72a3.71 3.71 0 0 0 3.709 3.709H90.25'></path>
        </g>
        <circle cx='64.918' cy='80.232' r='11.283' fill='#fff'></circle>
        <path
          className='fill-primary'
          d='M70.5 70.474a11 11 0 1 1-16.495 9.882L53.999 80l.006-.356a11 11 0 0 1 16.494-9.17m-1.423 6.548a1.1 1.1 0 0 0-1.452-.091l-.103.091-3.623 3.621-1.422-1.42-.103-.092a1.1 1.1 0 0 0-1.544 1.543l.092.104 2.2 2.2.103.09a1.1 1.1 0 0 0 1.348 0l.104-.09 4.4-4.4.091-.104a1.1 1.1 0 0 0-.091-1.452'
        ></path>
        <defs>
          <clipPath id='clip0_4214_17437'>
            <path
              fill='#fff'
              d='M.412 33.024 66.171 0l32.252 64.223-65.758 33.024z'
            ></path>
          </clipPath>
          <clipPath id='clip1_4214_17437'>
            <path
              fill='#fff'
              d='M33.576 33.024 99.335 0l32.252 64.223L65.83 97.247z'
            ></path>
          </clipPath>
          <clipPath id='clip2_4214_17437'>
            <path fill='#fff' d='M19.786 3.76h89.007v89.008H19.786z'></path>
          </clipPath>
        </defs>
      </svg>
    );

  return (
    <svg
      className={cn(iconVariants({ className: props.className }))}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 184 137'
    >
      <g
        stroke='#A8A29E'
        strokeLinecap='round'
        strokeLinejoin='round'
        clipPath='url(#clip0_1392_10417)'
      >
        <path
          fill='#fff'
          d='m104.895 92.581-38.353 19.261a8.7 8.7 0 0 1-6.526.53 8.29 8.29 0 0 1-4.907-4.169l-26.335-52.44a8.29 8.29 0 0 1-.414-6.426 8.7 8.7 0 0 1 4.322-4.918L59.53 30.936l28.583 9.099 20.692 41.203a8.29 8.29 0 0 1 .413 6.425 8.69 8.69 0 0 1-4.322 4.918'
        ></path>
        <path d='m59.527 30.936 7.525 14.983a4.14 4.14 0 0 0 2.453 2.085 4.34 4.34 0 0 0 3.263-.265l15.341-7.705'></path>
      </g>
      <g
        stroke='#A8A29E'
        strokeLinecap='round'
        strokeLinejoin='round'
        clipPath='url(#clip1_1392_10417)'
      >
        <path
          fill='#fff'
          d='m123.713 111.06-40.74-13.5a8.7 8.7 0 0 1-4.989-4.24 8.29 8.29 0 0 1-.521-6.418l18.459-55.703a8.29 8.29 0 0 1 4.251-4.836 8.69 8.69 0 0 1 6.533-.421l28.518 9.45 13.777 26.644-14.504 43.766a8.28 8.28 0 0 1-4.251 4.836 8.69 8.69 0 0 1-6.533.422'
        ></path>
        <path d='m135.223 35.39-5.274 15.916a4.14 4.14 0 0 0 .261 3.209 4.34 4.34 0 0 0 2.494 2.12l16.296 5.4'></path>
      </g>
      <g
        stroke='#A8A29E'
        strokeLinecap='round'
        strokeLinejoin='round'
        clipPath='url(#clip2_1392_10417)'
      >
        <path
          fill='#fff'
          d='M115.564 114.719H63.652a10.383 10.383 0 0 1-10.382-10.383V31.659a10.38 10.38 0 0 1 10.382-10.383h36.339l25.956 25.957v57.103a10.383 10.383 0 0 1-10.383 10.383'
        ></path>
        <path d='M99.988 21.276v20.765a5.19 5.19 0 0 0 5.192 5.192h20.764'></path>
      </g>

      <g clipPath='url(#clip3_1392_10417)'>
        <circle cx='90.484' cy='112.744' r='15.793' fill='#fff'></circle>
        <path
          className='fill-primary'
          d='M99.255 97.548a17.54 17.54 0 0 1 8.774 15.197 17.55 17.55 0 0 1-8.774 15.197 17.55 17.55 0 0 1-23.97-6.424 17.54 17.54 0 0 1-2.351-8.774l.008-.568a17.54 17.54 0 0 1 9.013-14.769 17.55 17.55 0 0 1 17.3.141m-8.774 20.461a1.755 1.755 0 0 0-1.754 1.755v.017a1.754 1.754 0 1 0 3.51 0v-.017a1.754 1.754 0 0 0-1.756-1.755m0-12.284a1.755 1.755 0 0 0-1.754 1.755v7.019a1.754 1.754 0 1 0 3.51 0v-7.019a1.756 1.756 0 0 0-1.756-1.755'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_1392_10417'>
          <path
            fill='#fff'
            d='M.191 46.664 92.24.438l45.146 89.898-92.047 46.226z'
          ></path>
        </clipPath>
        <clipPath id='clip1_1392_10417'>
          <path
            fill='#fff'
            d='M46.613 46.664 138.661.438l45.146 89.898-92.047 46.226z'
          ></path>
        </clipPath>
        <clipPath id='clip2_1392_10417'>
          <path fill='#fff' d='M27.313 5.703h124.59v124.59H27.313z'></path>
        </clipPath>
        <clipPath id='clip3_1392_10417'>
          <path fill='#fff' d='M69.426 91.687h42.115v42.115H69.426z'></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const EmptyStateIcon = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='[&>svg]:w-36 [&>svg]:h-36 [&>svg]:text-muted-foreground'>
      <NextEmptyStateIcon />
    </div>
  );
};

export const EmptyStateContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div>{children}</div>;
};

export const EmptyStateTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <h2 className='text-2xl font-bold text-piramid-text hidden'>{children}</h2>
  );
};

export const EmptyStateDescription = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <p className='text-sm font-medium text-piramid-text text-center leading-tight'>
      {children}
    </p>
  );
};

export const EmptyState = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='flex flex-col items-center justify-center p-8 rounded-lg min-h-[379px] text-center'>
      {children}
    </div>
  );
};
