import {
  Icon,
  IconFile,
  IconFileMusic,
  IconFileText,
  IconFileTypePdf,
  IconHeadphones,
  IconPhoto,
  IconQuestionMark,
  IconVideo,
} from '@tabler/icons-react';
import { FileTypes } from 'database';
import { format } from 'date-fns';
import {
  Badge,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  cn,
} from 'ui';
import { mimetypes } from './FileInput';
import React, { memo } from 'react';
import { TextFilePreview } from './TextFilePreview';

export const iconsByFileType: {
  [K in FileTypes]: Icon;
} = {
  pdf: IconFileTypePdf,
  other: IconQuestionMark,
  audio: IconFileMusic,
  document: IconFile,
  image: IconPhoto,
  video: IconVideo,
};

export const getFileType = (fileMimetype: string | null): FileTypes => {
  if (!fileMimetype) return 'other';

  for (const type in mimetypes) {
    const list = mimetypes[type as keyof typeof mimetypes];

    if (list.includes(fileMimetype)) {
      return type as FileTypes;
    }
  }

  return 'other';
};

export const FileCardList = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('grid grid-cols-3 gap-8', className)}>{children}</div>
  );
};

export const FileCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => {
  return (
    <Card
      {...props}
      className={cn(
        'relative group h-full cursor-pointer transition-all',
        className,
      )}
      ref={ref}
    />
  );
});

const FilePreview = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={cn('relative', className)}>{children}</div>;
};

const FilePreviewContent = ({
  previewURL = '/images/preview-placeholder.jpg',
  type,
  imageClassName,
  mode = 'full',
}: {
  previewURL?: string;
  type: FileTypes;
  imageClassName?: string;
  mode?: 'thumbnail' | 'full';
}) => {
  if (mode === 'thumbnail') {
    return (
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = '/images/preview-placeholder.jpg';
        }}
        className={cn(
          'h-full w-full object-top object-contain',
          imageClassName,
        )}
        src={previewURL}
      />
    );
  }

  switch (type) {
    case 'pdf':
      return (
        <div className='w-full h-full flex items-center justify-center z-0'>
          <iframe
            src={previewURL}
            className='w-full h-full rounded-md z-0'
            loading='lazy'
          />
        </div>
      );
    case 'video':
      return (
        <video
          className={cn('h-full w-full rounded-md object-top', imageClassName)}
          src={previewURL}
          muted
          loop
          onMouseOver={(e) => e.currentTarget.play()}
          onMouseOut={(e) => e.currentTarget.pause()}
          poster={previewURL}
        >
          <source src={previewURL} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      );
    case 'audio':
      return (
        <div className='h-full w-full bg-slate-50 flex items-center justify-center relative'>
          <audio className='w-full absolute bottom-0' controls src={previewURL}>
            Your browser does not support the audio element.
          </audio>
          <IconHeadphones className='h-20 w-20 text-primary' />
        </div>
      );
    case 'document':
      const getFileExtension = (url: string) => {
        const baseUrl = url.split('?')[0];
        return baseUrl?.split('.').pop()?.toLowerCase();
      };

      const fileExtension = previewURL ? getFileExtension(previewURL) : null;

      if (fileExtension && ['csv', 'txt'].includes(fileExtension)) {
        return <TextFilePreview previewURL={previewURL} />;
      }

      return (
        <div className='relative w-full h-full'>
          <object
            data={previewURL}
            type='application/msword'
            className='w-full h-full rounded-md absolute'
          >
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                previewURL,
              )}`}
              className='w-full h-full absolute'
            >
              <div className='h-full w-full bg-slate-50 flex items-center justify-center'>
                <IconFileText className='h-20 w-20 text-primary' />
              </div>
            </iframe>
          </object>
        </div>
      );
    default:
      return (
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/images/preview-placeholder.jpg';
          }}
          className={cn(
            'h-full w-full object-contain',
            'transition-transform duration-150 ease-out',
            imageClassName,
          )}
          src={previewURL}
          alt='File preview'
        />
      );
  }
};

const MemoizedFilePreviewContent = memo(
  FilePreviewContent,
  (prevProps, nextProps) => {
    return (
      prevProps.previewURL === nextProps.previewURL &&
      prevProps.type === nextProps.type &&
      prevProps.mode === nextProps.mode
    );
  },
);

export const FileCardPreview = ({
  type,
  previewURL,
  className,
  imageClassName,
  mode = 'full',
}: {
  type: FileTypes;
  previewURL?: string;
  className?: string;
  imageClassName?: string;
  mode?: 'thumbnail' | 'full';
}) => {
  return (
    <FilePreview className={cn(className)}>
      {type === 'audio' && mode === 'thumbnail' ? (
        <div className='h-full w-full bg-slate-50 flex items-center justify-center'>
          <IconHeadphones className='h-20 w-20 text-primary' />
        </div>
      ) : (
        <MemoizedFilePreviewContent
          previewURL={previewURL || ''}
          type={type}
          imageClassName={imageClassName}
          mode={mode}
        />
      )}
    </FilePreview>
  );
};

export const FileCardContent = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <CardContent className={cn('flex items-center justify-center', className)}>
      {children}
    </CardContent>
  );
};
export const FileCardTag = ({ children }: { children: React.ReactNode }) => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger>
          <Badge
            className='text-xs bg-card group-hover:text-secondary group-hover:bg-primary'
            variant={'outline'}
          >
            <span className='max-w-[6rem] w-full truncate'>{children}</span>
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <p className='text-xs'>{children}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const FileCardTagList = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className='flex flex-row gap-3 flex-wrap'>
      <div className='absolute right-0 -top-3'>
        <div className='flex flex-row flex-wrap gap-2'>{children}</div>
      </div>
    </div>
  );
};

export const FileCardFilename = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <CardTitle className='flex-row space-x-4 items-start truncate block !my-0'>
      {children}
    </CardTitle>
  );
};

export const FileCardIcon = ({
  mimetype,
  lookupMode = 'calculate',
  className,
}: {
  mimetype: string;
  lookupMode?: 'calculate' | 'rely';
  className?: string;
}) => {
  const Icon =
    iconsByFileType[
      lookupMode === 'calculate'
        ? getFileType(mimetype)
        : (mimetype as keyof typeof iconsByFileType)
    ];

  if (!Icon) return null;

  return (
    <Icon
      className={cn('w-2 h-2 mr-2 flex-shrink-0 text-primary', className)}
    />
  );
};

export const FileCardHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <CardHeader className='flex flex-row items-center'>{children}</CardHeader>
  );
};

export const FileCardAuthor = ({
  uploadedByEmail,
  createdAt,
}: {
  uploadedByEmail?: string;
  createdAt: string | Date;
}) => {
  return (
    <div>
      <p className='text-muted-foreground'>{uploadedByEmail} </p>
      <time className='text-muted-foreground'>
        {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
      </time>
    </div>
  );
};

export const FileCardFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <CardFooter className='flex flex-col text-xs space-y-4 items-start'>
      {children}
    </CardFooter>
  );
};
