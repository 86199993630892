'use client';

import React, { createContext, useContext, useState, useCallback } from 'react';
import {
  ListFilesResponseBody,
  SearchTagsResponseBody,
  SearchTagsRequestQuery,
  FileWithURLAndTags,
} from 'bff';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Button,
  cn,
} from 'ui';
import { IconTag, IconX } from '@tabler/icons-react';
import { DialogClose } from '@radix-ui/react-dialog';
import { FileCarousel } from './FileCarousel';
import { FileTypes } from 'database';
import { FileTagEditor } from './FileTagEditor';
import { InferredFileDataEditor } from './InferredFileDataEditor';
import { useQuery } from '@tanstack/react-query';
import { FileCardPreview } from './FileCard';

type File = ListFilesResponseBody['files'][number] & {
  _version?: number;
  type: FileTypes;
};

export const SINGLE_FILE_QUERY_KEY = 'single-file';

interface FileViewerContextType {
  isOpen: boolean;
  currentFile: File | null;
  currentIndex: number;
  files: File[];
  openFileViewer: (file: File, files: File[]) => void;
  closeFileViewer: () => void;
  goToNext: () => void;
  goToPrevious: () => void;
  fetchFile: (id: string, cookie?: string) => Promise<FileWithURLAndTags>;
}

const FileViewerContext = createContext<FileViewerContextType | undefined>(
  undefined,
);

export const useFileViewer = () => useContext(FileViewerContext);

interface FileViewerProviderProps {
  children: React.ReactNode;
  updateFileTag?: (
    fileId: string,
    tagId: string,
    inferredFileDataId?: string,
  ) => Promise<any> | undefined;
  updateInferredKeyword?: (
    fileId: string,
    inferredFileDataId: string,
    keywordId: string,
    field: 'key' | 'value',
    newValue: string,
  ) => Promise<any> | undefined;
  searchTags: (
    query: SearchTagsRequestQuery,
    cookie?: string,
  ) => Promise<SearchTagsResponseBody>;
  fetchFile: (id: string, cookie?: string) => Promise<FileWithURLAndTags>;
  userType?: string;
}

export const FileViewerProvider: React.FC<FileViewerProviderProps> = ({
  children,
  updateFileTag,
  updateInferredKeyword,
  searchTags,
  fetchFile,
  userType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialFile, setInitialFile] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);

  const { data: fetchedFile } = useQuery({
    queryKey: [SINGLE_FILE_QUERY_KEY, initialFile?.id],
    queryFn: () => (initialFile ? fetchFile(initialFile.id) : null),
    enabled: !!initialFile?.id && isOpen,
    initialData: null,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const currentFile =
    fetchedFile && initialFile
      ? {
          ...initialFile,
          ...fetchedFile,
          type: initialFile.type,
        }
      : initialFile;

  const currentIndex = currentFile
    ? files.findIndex((file) => file.id === currentFile.id)
    : -1;

  const openFileViewer = useCallback((file: File, allFiles: File[]) => {
    setInitialFile(file);
    setFiles(allFiles);
    setIsOpen(true);
  }, []);

  const closeFileViewer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const goToNext = useCallback(() => {
    if (currentIndex < files.length - 1) {
      const nextFile = files[currentIndex + 1];
      if (nextFile) {
        setInitialFile(nextFile);
      }
    }
  }, [currentIndex, files]);

  const goToPrevious = useCallback(() => {
    if (currentIndex > 0) {
      const prevFile = files[currentIndex - 1];
      if (prevFile) {
        setInitialFile(prevFile);
      }
    }
  }, [currentIndex, files]);

  const contextValue = {
    isOpen,
    currentFile,
    currentIndex,
    files,
    openFileViewer,
    closeFileViewer,
    goToNext,
    goToPrevious,
    fetchFile,
  };

  return (
    <FileViewerContext.Provider value={contextValue}>
      {children}

      {isOpen && currentFile && (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogContent
            className='h-[38.6875rem] max-w-[77.375rem] place-content-start rounded-[1.25rem] shadow-none'
            hideClose
          >
            <DialogHeader className='flex flex-row items-center justify-between'>
              <div className='flex flex-row items-center space-x-2'>
                <IconTag className='w-[1.0625rem] h-[1.0625rem] text-primary' />
                <DialogTitle className='font-semibold text-base text-stone-700'>
                  {currentFile.tags &&
                    currentFile.tags.map((tag) => tag.name).join(', ')}
                </DialogTitle>
              </div>
              <DialogClose asChild>
                <Button
                  variant='ghost'
                  className='hover:bg-transparent'
                  tabIndex={-1}
                  onClick={closeFileViewer}
                >
                  <IconX className='w-6 h-6 text-stone-400' />
                </Button>
              </DialogClose>
            </DialogHeader>
            <div className='flex items-start'>
              <div className='w-[53.8125rem] h-[28.375rem] relative'>
                <FileCarousel
                  files={files}
                  currentFile={currentFile}
                  currentIndex={currentIndex}
                  onNext={goToNext}
                  onPrevious={goToPrevious}
                >
                  <FileCardPreview
                    previewURL={currentFile.url}
                    type={currentFile.type}
                    mode='full'
                    className={cn('h-full', {
                      'pointer-events-auto': currentFile.type === FileTypes.pdf,
                    })}
                    imageClassName={cn('object-contain')}
                  />
                </FileCarousel>
              </div>
              <div className='h-full ps-4'>
                <div className='flex flex-col w-78 overflow-y-auto h-[32.5rem]'>
                  <h1 className='px-0 font-semibold text-base_brand text-stone-700'>
                    Inferencias
                  </h1>
                  {!currentFile.tags.length && (
                    <div className='flex flex-row items-center justify-start h-10'>
                      <IconTag className='w-[0.875rem] h-[0.875rem] text-primary' />
                      <p className='text-xs font-medium text-primary p-1 animate-pulse'>
                        Analizando...
                      </p>
                    </div>
                  )}
                  {currentFile.tags && currentFile.tags.length > 0 && (
                    <FileTagEditor
                      file={currentFile}
                      updateFileTag={updateFileTag || undefined}
                      searchTags={searchTags || undefined}
                      userType={userType}
                    />
                  )}
                  {currentFile.InferredFileData && (
                    <div className='flex flex-col space-y-6 text-base_brand animate-in fade-in duration-1000'>
                      <div>
                        <InferredFileDataEditor
                          fileId={currentFile.id}
                          inferredFileData={currentFile.InferredFileData}
                          onUpdateKeyword={updateInferredKeyword}
                          userType={userType}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </FileViewerContext.Provider>
  );
};
