import { User } from 'database';
import { customFormatDistance } from 'utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  ScrollArea,
  Separator,
  Skeleton,
  cn,
} from 'ui';
import { getInitialsFromUser } from 'utils';
import { useSharedAuth } from '..';
import { es } from 'date-fns/locale';

const MessageSeparator = () => {
  return <Separator className='my-6' />;
};

const LoadingState = () => {
  const SAMPLE_MESSAGES_COUNT = 5;

  return (
    <div className='flex flex-col'>
      {new Array(SAMPLE_MESSAGES_COUNT).fill(null).map((_, index) => {
        return (
          <div key={index}>
            <div className='flex items-center space-x-4'>
              <Skeleton className='h-12 w-12 rounded-full' />
              <div className='space-y-2'>
                <Skeleton className='h-4 w-[250px]' />
                <Skeleton className='h-4 w-[200px]' />
              </div>
            </div>
            {index + 1 !== SAMPLE_MESSAGES_COUNT && (
              <Separator className='my-6' />
            )}
          </div>
        );
      })}
    </div>
  );
};

const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <ScrollArea
      orientation='vertical'
      className='flex flex-col h-[600px] w-full'
    >
      {children}
    </ScrollArea>
  );
};

const MessageTitle = ({
  sender,
  created_at,
}: {
  created_at: string | Date;
  sender: Pick<User, 'first_name' | 'last_name' | 'profile_photo' | 'id'>;
}) => {
  const auth = useSharedAuth();

  return (
    <div className='flex flex-row space-x-2 items-center'>
      <p className='font-bold leading-relaxed text-sm'>
        {auth?.user?.id === sender.id
          ? `${auth.user.first_name} ${auth.user.last_name} `
          : `${sender.first_name} ${sender.last_name || ''}`}
      </p>
      <p className='text-xs text-piramid-text-secondary'>
        {customFormatDistance(new Date(), new Date(created_at), {
          locale: es,
          addSuffix: true,
        })}
      </p>
    </div>
  );
};

const MessageContent = ({ children }: { children: React.ReactNode }) => {
  return <div className='flex flex-col space-y-2 w-full'>{children}</div>;
};

const MessageText = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <p
      className={cn(
        'leading-relaxed text-sm whitespace-pre-wrap max-w-4xl w-full',
        className,
      )}
    >
      {children}
    </p>
  );
};
const MessageAvatar = ({
  sender,
}: {
  sender: Pick<User, 'first_name' | 'last_name' | 'profile_photo'>;
}) => {
  return (
    <Avatar>
      <AvatarImage src={sender.profile_photo!} />
      <AvatarFallback>{getInitialsFromUser(sender)}</AvatarFallback>
    </Avatar>
  );
};

export const Message = ({ children }: { children: React.ReactNode }) => {
  return <div className='flex flex-row items-start space-x-4'>{children}</div>;
};

Message.Avatar = MessageAvatar;
Message.Content = MessageContent;
Message.Text = MessageText;
Message.Title = MessageTitle;
Message.Container = Container;
Message.LoadingState = LoadingState;
Message.Separator = MessageSeparator;
