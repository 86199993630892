import { useEffect, useRef, RefObject } from 'react';

export const useScrollToBottom = <T extends HTMLElement>(
  deps: any[] = [],
): RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, deps);

  return ref;
};
