'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import {
  AttachFilesRequestBody,
  TokenizeFileRequestBody,
  TokenizeFileResponseBody,
} from 'bff';
import { useCallback, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  UploadFile,
  useApiError,
  useFileUpload,
  DOCUMENTATION_FILES_QUERY_KEY,
  UploadFileRef,
} from 'shared-components';
import {
  Button,
  ComboboxOption,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  useToast,
} from 'ui';
import z from 'zod';
import { MAX_CLAIM_FILES_PER_UPLOAD } from 'piramid-constants';

const uploadFileCustomFormData = z.object({
  no_tagged_files: z.string(),
});

export type UploadFileCustomFormData = z.infer<typeof uploadFileCustomFormData>;

export const UploadFileCustom = ({
  tokenizeFile,
  classNames,
  addFiles,
  uploadMessage,
}: {
  classNames?: {
    container?: string;
    input?: string;
  };
  tokenizeFile: (
    input: TokenizeFileRequestBody,
  ) => Promise<TokenizeFileResponseBody>;
  addFiles: (files: AttachFilesRequestBody['files']) => Promise<any>;
  uploadMessage?: string;
}) => {
  const [selectedTag, setSelectedTag] = useState<ComboboxOption | null>(null);

  const form = useForm<UploadFileCustomFormData>({
    resolver: zodResolver(uploadFileCustomFormData),
  });

  const file = useFileUpload({
    tokenizer: async (fileData) => tokenizeFile(fileData),
    maxFiles: MAX_CLAIM_FILES_PER_UPLOAD,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const { toast } = useToast();

  const queryClient = useQueryClient();

  const uploadFileRef = useRef<UploadFileRef>(null);

  const onUploadFileCustom = useCallback(() => {
    setIsLoading(true);
    addFiles(
      file.files.map((file) => ({
        token: file.token,
        tags: selectedTag ? [selectedTag.value.toString()] : [],
      })),
    )
      .then(() => {
        queryClient.invalidateQueries([DOCUMENTATION_FILES_QUERY_KEY]);
        setSelectedTag(null);
        uploadFileRef.current?.reset();

        toast({
          title: 'Archivos adjuntados',
          description: 'Los archivos se han adjuntado correctamente',
        });
      })
      .catch(handleError)
      .finally(() => {
        setIsLoading(false);
        file.setFiles([]);
        form.reset();
      });
  }, [file.files, addFiles, selectedTag, setSelectedTag]);

  const formId = 'upload-no-tagged-file-form';

  return (
    <div className={classNames?.container}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onUploadFileCustom)} id={formId}>
          <div className='relative'>
            <FormField
              control={form.control}
              name='no_tagged_files'
              defaultValue=''
              render={() => {
                return (
                  <FormItem>
                    <FormControl>
                      <UploadFile
                        ref={uploadFileRef}
                        {...file}
                        defaultValue={[]}
                        uploadMessage={uploadMessage}
                        className={classNames?.input}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {file.files.length > 0 && (
              <div className='absolute bottom-4 right-4 z-50'>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  type='submit'
                  className='text-base_brand h-8 py-2 px-3 font-medium text-white bg-primary'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    form.handleSubmit(onUploadFileCustom)();
                  }}
                >
                  Cargar documento
                </Button>
              </div>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
};
