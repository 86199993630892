import {
  IconArrowBackUpDouble,
  IconArrowForwardUpDouble,
  IconChevronsRight,
} from '@tabler/icons-react';
import { PaginationState, Table } from '@tanstack/react-table';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
} from 'lucide-react';
import { Button } from './Button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './Select';
import { cn } from '..';

export interface DataTablePaginationProps<TData> {
  table: Pick<
    Table<TData>,
    | 'setPageSize'
    | 'getPageCount'
    | 'setPageIndex'
    | 'getCanPreviousPage'
    | 'previousPage'
    | 'nextPage'
    | 'getCanNextPage'
  > & {
    getState: () => { pagination: PaginationState };
  };
}

const capitalizeFirstLetter = (value: string = ''): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

export function DataTablePagination<TData>({
  table,
  entityLabel,
  classNames,
}: DataTablePaginationProps<TData> & {
  entityLabel: string;
  classNames?: {
    entityLabel?: string;
    pageIndicator?: string;
    buttonGroupContainer?: string;
    selectTrigger?: string;
    container?: string;
    containerSegments?: string;
  };
}) {
  if (!table.getPageCount()) return null;

  return (
    <div
      className={cn(
        'flex items-end justify-end px-2 mt-4',
        classNames?.container,
      )}
    >
      <div
        className={cn(
          'flex items-center space-x-6 lg:space-x-8 text-piramid-text justify-between w-full',
          classNames?.containerSegments,
        )}
      >
        <div className='flex items-center space-x-2'>
          <p className={cn('text-sm font-medium', classNames?.entityLabel)}>
            {capitalizeFirstLetter(entityLabel)} por página
          </p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => table.setPageSize(Number(value))}
          >
            <SelectTrigger
              className={cn('h-8 w-[70px]', classNames?.selectTrigger)}
            >
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side='top'>
              {[10, 20].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div
          className={cn(
            'flex w-[100px] items-center justify-center text-sm font-medium',
            classNames?.pageIndicator,
          )}
        >
          Página {table.getState().pagination.pageIndex + 1} de{' '}
          {table.getPageCount()}
        </div>

        <div
          className={cn(
            'flex items-center space-x-2',
            classNames?.buttonGroupContainer,
          )}
        >
          <Button
            variant='outline'
            className='hidden h-8 w-8 p-0 lg:flex'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className='sr-only'>Go to first page</span>
            <ChevronsLeftIcon className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='h-8 w-8 p-0'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className='sr-only'>Ir a la página anterior</span>
            <ChevronLeftIcon className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='h-8 w-8 p-0'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className='sr-only'>Ir a la siguiente página</span>
            <ChevronRightIcon className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='hidden h-8 w-8 p-0 lg:flex'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className='sr-only'>Ir a la ultima página</span>
            <IconChevronsRight className='h-4 w-4' />
          </Button>
        </div>
      </div>
    </div>
  );
}
